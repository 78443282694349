<template>
  <div class="coin-recharge">
    <van-config-provider>
      <van-nav-bar :title="$t('title.historyData')" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
    </van-config-provider>
    <van-pull-refresh v-model="refreshing" @refresh="refreshData">
      <div class="order-list">
        <van-list
          v-if="dataList.length >0"
          v-model:loading="loading"
          :finished="finished"
          :finished-text="$t('common.noMore')"
          @load="loadData"
        >
          <div class="journal-block white" v-for="item in dataList" :key="item.sn">
            <van-cell-group :border="false">
              <van-cell :title="$t('field.orderNumber')" :value="item.sn" class="journal-title" value-class="journal-sn"/>
              <div class="journal-detail">
                <van-cell :title="$t('field.bankName')" :value="item.bankName" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                <van-cell :title="$t('field.bankCardNumber')" :value="item.cardNo" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                <van-cell :title="$t('field.cardholder')" :value="item.cardholder" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                <van-cell :title="$t('field.payAmount')" :value="`${item.amount} ${item.currency}`" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                <van-cell :title="$t('field.exchangeRate')" :value="`1 : ${item.exchangeRateStr}`" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                <van-cell :title="$t('field.amount')" :value="`${item.payAmountStr} ${item.payCurrency}`" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                <van-cell :title="$t('field.commission')" :value="`${item.fee} ${item.payCurrency}`" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                <van-cell :title="$t('field.receivedAmount')" :value="`${item.resultAmountStr} ${item.payCurrency}`" :border="false" class="journal-detail-row" title-class="journal-label" value-class="coin-price" />
                <template v-if="$AppData.config.themeType">
                  <p :class="`water-mark ${orderStatus(item.status)}`">{{item.statusStr}}</p>
                  <van-cell :title="$t('field.orderTime')" :value="item.createTimeStr" :border="false" class="journal-detail-row" />
                </template>
              </div>
              <van-cell v-if="!$AppData.config.themeType" :title="item.createTimeStr" :value="item.statusStr" :border="false" class="order-date" :value-class="`order-status ${orderStatus(item.status)}`" />
            </van-cell-group>
          </div>
        </van-list>
        <div v-else>
          <van-empty :description="$t('common.noData')"></van-empty>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from 'vue';
import i18n from '@/assets/lang/index';
import { queryLegalWithdrawHistory } from "@/api";

export default {
  setup() {
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const dataList = ref([]);
    return {
      loading,
      finished,
      refreshing,
      dataList,
    };
  },
  
  data() {
    return {
      current: 1,
    };
  },
  
  mounted() {
    this.fetchOrderList();
  },

  methods: {
    fetchOrderList() {
      queryLegalWithdrawHistory({
        pageSizeStr: 10,
        pageNumberStr: this.current,
      }).then(res => {
        this.dataList = this.dataList.concat(res.data || []);
        this.current = res.pageNumber + 1;
        this.loading = false;
        if (res.totalNumber <= res.pageNumber * res.pageSize) {
          this.finished = true;
        }
      });
    },
    loadData() {
      if (this.refreshing) {
        this.dataList = [];
        this.current = 1;
        this.refreshing = false;
      }
      this.fetchOrderList();
    },
    refreshData() {
      // 清空列表数据
      this.finished = false;
            
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData();
    },
    orderStatus(status) {
      if(status === 1) {
        return 'green';
      } else if(status === 2){
        return 'red';
      } else {
        return '';
      }
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less"></style>
